<template>
  <v-card
      class="card-shadow border-radius-xl"
  >
    <div class="px-6 pt-4 pb-0 d-flex">
      <v-avatar
          :color="`bg-gradient-${data.iconColor} border-radius-xl mt-n8`"
          class="shadow"
          height="64"
          width="64"
      >
        <v-icon class="material-icons-round text-white" size="24"
        >{{ data.icon }}
        </v-icon
        >
      </v-avatar>
      <p
          class="font-weight-bold text-h4 mb-0 ms-3"
      >
        {{ data.title }}
      </p>
    </div>
    <v-card-text>
      <hr>
        <v-list>
          <v-list-item
            v-for="(item, index) in data.items"
            :key="index"
            :class="{'list-item-border': index !== data.items.length - 1}"
          >
            <v-row class="w-100">
              <!-- Prima colonna per le etichette -->
              <v-col class="text-left" cols="7">
                <span class="text-h6">{{ item.text }}</span>
              </v-col>

              <!-- Seconda colonna per i valori -->
              <v-col class="text-left" cols="5">
                <span class="text-h6 text-pink font-weight-bold">{{ item.value }}</span>
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>

    </v-card-text>
    <v-card-text v-if="data.infoFooter">
      <hr>
      <span class="font-weight-bold">{{ data.infoFooter.text }}</span>
<!--      {{ data.media.text }} - {{ data.media.value }}-->
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  name: "StatsCard",
  props: {
    data: Object
  },
  data() {
    return {}
  },
  mounted() {
    console.log('this.data: ', this.data)
  },
  methods: {}
}
</script>
<style scoped>
hr {
  color: #aaceec;
}
.list-item-border {
  border-bottom: 1px solid #e0e0e0; /* Scegli il colore del bordo che preferisci */
}
.v-list-item:nth-child(odd) {  /* Seleziona gli elementi in posizioni dispari */
  background-color: #ffffff; /* Colore verde chiaro */
}

.v-list-item:nth-child(even) {  /* Seleziona gli elementi in posizioni pari */
  background-color: #eaeaea; /* Colore blu chiaro */
}
</style>