<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mb-6 mt-15">
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <stats-card :data="statsIncassi"></stats-card>
        </v-col>
        <v-col lg="8" class="pt-0 mb-lg-0 mb-10">
          <v-row>
            <v-col>
              <stats-card-composto :data="statsMagazzino"></stats-card-composto>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <stats-card-ordini :data="statsOrdini"></stats-card-ordini>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        <v-col md="12">

        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import StatsCard from "@/components/Custom/StatsCard.vue";
import StatsCardOrdini from "@/components/Custom/StatsCardOrdini.vue";
import StatsService from "@/services/stats.service";
import StatsCardComposto from "@/components/Custom/StatsCardComposto.vue";
export default {
  name: "Dashboard2",
  components: {
    StatsCard,
    StatsCardComposto,
    StatsCardOrdini
  },
  data() {
    return {
      statsIncassi: {
        title: 'Ultimi Incassi',
        icon: 'language',
        iconColor: 'success',
        items: [],
        infoFooter: {
          text: 'Media Mese',
          value: '40'
        }
      },
      statsMagazzino: {
        title: 'Magazzino',
        icon: 'warehouse',
        iconColor: 'info',
        items: []
      },
      statsOrdini: {
        title: 'Ultimi Ordini',
        icon: 'home',
        iconColor: 'danger',
        items: []
      }
    };
  },
  mounted() {
    this.getIncassi()
    this.getMagazzino()
    this.getOrders()
  },
  methods: {
    transformKeysToLabels(data) {
      const keyMap = {
        totale_bottiglie: 'Totale Bottiglie',
        totale_etichette: 'Totale Etichette',
        totale_produttori: 'Totale Produttori',
        totale_costo_magazzino: 'Totale Costo Vini in Store',
        totale_magazzino: 'Totale Valore Magazzino'
      };

      // Trasforma le chiavi utilizzando la mappa definita sopra
      let transformedData = {};
      for (const [key, value] of Object.entries(data)) {
        const newKey = keyMap[key] || key; // Usa la chiave originale come fallback
        transformedData[newKey] = value;
      }
      return transformedData
    },
    formatDate(dateString) {
      const [date,] = dateString.split('T'); // Divide la stringa in data e ora, ignorando l'ora
      const [year, month, day] = date.split('-'); // Separa gli elementi della data

      // Crea un nuovo oggetto Date
      const dateObj = new Date(date);

      // Array dei nomi dei mesi
      const monthNames = [
        'Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno',
        'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'
      ];

      // Array dei nomi dei giorni della settimana
      const dayNames = [
        'Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'
      ];

      // Ottieni il nome del mese
      const monthName = monthNames[parseInt(month) - 1];

      // Ottieni il giorno della settimana
      const dayOfWeek = dayNames[dateObj.getDay()];

      // Riorganizza gli elementi nel formato desiderato con il nome del giorno e del mese
      return `${dayOfWeek}, ${day} ${monthName} ${year}`;
    },
    getIncassi() {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      StatsService.getUltimiIncassi(params).then(resp => {
        this.statsIncassi.items = resp.data.map(item => ({
          ...item, // Mantiene le altre proprietà invariate
          text: this.formatDate(item.data), // Trasforma 'created_at' in 'text'
          value: `${item.incasso} €`, // Trasforma 'incasso' in 'value'
        }));
      }).catch().finally()
    },
    getMagazzino() {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      StatsService.getMagazzinoStats(params).then(resp => {

        //{ "total_bottles": 6651, "total_cost": 94466.31, "total_value": 268715.7 }
        this.statsMagazzino.items = this.transformKeysToLabels(resp.data)
/*        if (resp.data.totale_costo_magazzino) {
          this.statsMagazzino.items = resp.data.totale_costo_magazzino['total_cost']
        }*/
      }).catch().finally()
    },
    getOrders() {
      const params = {
        store: this.$store.state.auth.user.store_id
      }
      StatsService.getOrderStats(params).then(resp => {
        this.statsOrdini.items = resp.data.map(item => ({
          ...item, // Mantiene le altre proprietà invariate
          text: `${item.winemaker_name || "Sconosciuto"}`,
          value: `Bottiglie ordinate: ${item.total_bottle_order}, Status: ${item.status}`
        }));
      }).catch().finally()
    }
  }
};
</script>
